import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MicroFrontend from "./MicroFrontend";

import { siteConfig } from './mfe-config';

const { REACT_APP_PATIENT_PORTAL_REMOTE: remoteHost } = process.env;

const Routes = () => (
  <Switch>
    <Route path="/" render={() => (
      <MicroFrontend
        host={remoteHost}
        name="PatientPortalMfe"
        mfeProps={siteConfig}
      />
    )} />
    <Redirect to={{ pathname: '/' }} />
  </Switch>
);

export default Routes;
