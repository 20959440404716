import YesIcon from 'assets/images/yes-icon.svg';

import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';

import Logo from 'assets/images/logo.svg';
import loginLogo from 'assets/images/login-logo.svg';
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_AWS_REGION: region,
  REACT_APP_COGNITO_POOL_ID: userPoolId,
  REACT_APP_COGNITO_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
} = process.env;

const locations = [
  { key: 'LOC1', name: 'Loganville Family Dentistry'},
  { key: 'LOC2', name: 'Kids Corner'},
  { key: 'LOC3', name: 'Cottage Dental'},
  { key: 'LOC4', name: "Eagle's Landing"},
  { key: 'LOC5', name: 'Hal N. Arnold, DMD'},
  { key: 'LOC6', name: 'The Lodi Dentist'},
  { key: 'LOC7', name: 'Absolute Dental'},
  { key: 'LOC8', name: 'On Pointe Dentistry'},
  { key: 'LOC9', name: 'All Smiles Dental Care'},
  { key: 'LOC10', name: 'Open Wide Dental'}
];

const locationApptLinks = locations.map((loc) => {
  const {
    [`REACT_APP_${loc.key}_APPT_LINK`]: locApptLink,
  } = process.env;
  return {
    name: loc.name,
    link: locApptLink,
    target: '_blank',
  };
});

const config = {
  groupId,
  region,
  userPoolId,
  userPoolWebClientId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: 'Bright Smiles Dental Plans',
  tokenName: 'brightSmilesSubscriberToken',
  globalClass: 'brightSmiles-container',
  clientHeader,
  enableHeroButton: true,
  pricingType: 'standard-pricing',
  headerProps: {
    enable: true,
    aboutUs: { useWebsiteLink: true },
  },
  homeTemplateProps: {
    heroSection: {
      variant: 1,
      primaryButtonProps: {},
      heroImage: '/images/home-images/home-banner-img.webp',
      mobileHeroImage: '/images/home-images/header-img-ipad.webp',
      primaryButtonProps: {
        to: '/find-a-provider',
      },
      secondaryButton: 'Login',
      secondaryButtonProps: {
        to: '/login',
      },
      
      alignContent: 'center'
    },
    benefitsSection: {
      variant: 2,
      columns: 3,
    },
    subscriptionSection: {
      tabVariant: 2,
      carouselResponsiveItems: {
        desktop: 3,
      },
      planCardProps: {
        buttonProps: {
          highlightedColor: 'primary',
          highlightedVariant: 'contained',
        },
      },
    },
    howItWorksSection: {
      variant: 2,
      alignTitle: 'left',
      heroImage: '/images/home-images/how-it-works.webp',
    },
    testimonialSection: {
      variant: 2,
      messageType: 'h6',
      gridSpacing: {
        xSmall: 12,
        medium: 9,
      },
      carouselResponsiveItem: {
        superLarge: 1,
        large: 1,
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
    },
  },
  checkoutSection: {},
  findProviderSection: {
    locationWrapper: 7,
    mapWrapper: 5,
  },
  choosePlanSection: {
    sectionSubtitle: '',
    locationLogo: Logo,
    cardVariant: 2,
    tabVariant: 2,
    tabLabel: {
      annual: 'Billed Annually',
      annual_billed_monthly: 'Billed Monthly',
    },
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        highlightedColor: 'primary',
        normalVariant: 'contained',
        highlightedVariant: 'outlined',
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
    },
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: true,
    bookAppointmentProps: {
      target: '_blank',
      locationList: locationApptLinks,
    },
  },
  savingsSummary: {
    enableFooterbar: true,
    contactSection: 'contactDetails',
    moreSavingsButtonProps: {
      variant: 'contained',
      color: 'primary',
    },
  },
  faqSection: {
    heroProps: {
      secondaryButtonProps: {
        variant: 'outlined',
        color: 'secondary',
      },
    },
  },
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  bookAppointment: {
    enableBookAppointment: false,
  },
  footer: {
    enable: true,
    logo: Logo,
  },
  loginPage: {
    logo: loginLogo,
    bannerImage: './images/login.webp',
  },
  theme: Theme,
};

export default config;
