import React, { useEffect } from "react";

const MicroFrontend = ({ name, host, history, mfeProps }) => {
  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`;

    const renderMicroFrontend = () => {
      if (window[`render${name}`])
        window[`render${name}`](`${name}-container`, history, mfeProps);
    };

    if (document.getElementById(scriptId)) {
      renderMicroFrontend();
      return;
    }

    try {
      fetch(`${host}/asset-manifest.json`)
        .then((res) => res.json())
        .then((manifest) => {
          const script = document.createElement("script");
          script.id = scriptId;
          script.crossOrigin = "";
          script.src = `${host}${manifest.files["main.js"]}`;
          script.onload = () => {
            renderMicroFrontend();
          };
          document.head.appendChild(script);

          console.log(manifest.files);
          
          if(manifest?.files?.["main.css"]) {
            console.log(`${host}${manifest.files["main.css"]}`);

            const cssLink = document.createElement("link");
            cssLink.rel = 'stylesheet';
            cssLink.crossOrigin = "";
            cssLink.href = `${host}${manifest.files["main.css"]}`;
            document.head.appendChild(cssLink);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    catch (err) {
      console.log(err);
    }

    return () => {
      window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
    };
  });

  return <main id={`${name}-container`} />;
}

MicroFrontend.defaultProps = {
  document,
  window,
};

export default MicroFrontend;