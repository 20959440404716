import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from 'theme';

// App Routes
import Routes from './Routes';

import { ErrorBoundary } from './helper-components';

const App = () => (
  // Boundary to capture error in the Application
  <ErrorBoundary>
    <ThemeProvider theme={Theme}>

      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />

      <BrowserRouter>
        <Routes />
      </BrowserRouter>

    </ThemeProvider>
  </ErrorBoundary>
);

export default App;
